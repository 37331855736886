h1,
h3,
p,
blockquote,
pre,
li,
label {
  font-family: "Inter", apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Arial, "sans-serif", "Apple Color Emoji", "Segoe UI Emoji";
  margin: 0;
}
body, #root, .full {
  height: 100%;
  width: 100%;
}
.app {
  display: flex;
  flex-flow: column;
  height: 100%;
}
/* Header */
.header { 
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
  flex: 0 1 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.title {
  display: flex;
  align-items: center;
}
.title img {
  height: 24px;
  width: 28px;
  margin-right: 8px;
}

/* content */
.flex {
  display: flex;
}
.flex-one {
  flex: 1;
}
.height-overflow {
  height: 100%;
  overflow: hidden;
}
.markdown-scroll-container {
 /* flex: 1; */
}
.markdown-wrapper {
  background-color: #f7f8f9;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}
.markdown-page {
  background-color: #ffffff;
  border: 1px solid #e5e6e7;
  -webkit-box-shadow: 0 0 10px #d3d4d5;
  box-shadow: 0 0 10px #d3d4d5;
  padding: 20px;
}
.markdown-img {
  max-width: 100%;
}
/* Navigation */
.navbar {
  width: 350px;
  flex: 0 0 350px;
  border-right: 1px solid #eee;
  padding-left: 0;
  margin: 0;
  height: 100%;
}
.navbar ul {
  padding-left: 0;
  margin: 8px;
}
.navbar li {
  list-style: none;
  margin-bottom: 12px;
  padding: 12px;
  cursor: pointer;
  border-radius: 4px;
}
.navbar li:last-child {
  margin-bottom: 0;
}
.navbar li a {
  color: black;
  text-decoration: none;
}
.navbar li:hover {
  background-color: rgba(33, 182, 154, 0.15);
}
.navbar li.selected {
  background-color: rgba(33, 182, 154, 0.4);
}
.navbar .search {
  margin: 8px;
}
.navbar .search > input {
  padding: 8px;
  border-radius: 4px;
  font-size: 1rem;
  border: 1px solid lightgray;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
.navbar .list {
  height: 100%;
  overflow-y: scroll;
}

/* Right panel */
.right {
  width: 400px;
  flex: 0 0 400px;
  border-left: 1px solid #eee;
  overflow-y: scroll;
}

.yfmAccordion {
  padding-right: 0;
}

.yfmAccordion > .euiAccordion__childWrapper {
  padding: 8px 0px 8px 18px;
}
.yfmAccordion .euiAccordion__padding--s {
  padding-right: 0;
}
#attriubutesAccordion {
  padding-right: 8px;
}

.accordion-arrayValue {
  color: #98a2b3;
}

/* Shameless copy from Github - https://github.githubassets.com/assets/github-b0630e9435ee86e70a1ea833a291f3bf.css */
.markdown-body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word
}

.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: 1px solid #d1d5da;
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 #d1d5da
}

.markdown-body:after,.markdown-body:before {
  display: table;
  content: ""
}

.markdown-body:after {
  clear: both
}

.markdown-body>:first-child {
  margin-top: 0!important
}

.markdown-body>:last-child {
  margin-bottom: 0!important
}

.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none
}

.markdown-body .absent {
  color: #cb2431
}

.markdown-body .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1
}

.markdown-body .anchor:focus {
  outline: none
}

.markdown-body blockquote,.markdown-body details,.markdown-body dl,.markdown-body ol,.markdown-body p,.markdown-body pre,.markdown-body table,.markdown-body ul {
  margin-top: 0;
  margin-bottom: 16px
}

.markdown-body hr {
  height: .25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0
}

.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: .25em solid #dfe2e5
}

.markdown-body blockquote>:first-child {
  margin-top: 0
}

.markdown-body blockquote>:last-child {
  margin-bottom: 0
}

.markdown-body h1,.markdown-body h2,.markdown-body h3,.markdown-body h4,.markdown-body h5,.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25
}

.markdown-body h1 .octicon-link,.markdown-body h2 .octicon-link,.markdown-body h3 .octicon-link,.markdown-body h4 .octicon-link,.markdown-body h5 .octicon-link,.markdown-body h6 .octicon-link {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden
}

.markdown-body h1:hover .anchor,.markdown-body h2:hover .anchor,.markdown-body h3:hover .anchor,.markdown-body h4:hover .anchor,.markdown-body h5:hover .anchor,.markdown-body h6:hover .anchor {
  text-decoration: none
}

.markdown-body h1:hover .anchor .octicon-link,.markdown-body h2:hover .anchor .octicon-link,.markdown-body h3:hover .anchor .octicon-link,.markdown-body h4:hover .anchor .octicon-link,.markdown-body h5:hover .anchor .octicon-link,.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible
}

.markdown-body h1 code,.markdown-body h1 tt,.markdown-body h2 code,.markdown-body h2 tt,.markdown-body h3 code,.markdown-body h3 tt,.markdown-body h4 code,.markdown-body h4 tt,.markdown-body h5 code,.markdown-body h5 tt,.markdown-body h6 code,.markdown-body h6 tt {
  font-size: inherit
}

.markdown-body h1 {
  font-size: 2em
}

.markdown-body h1,.markdown-body h2 {
  padding-bottom: .3em;
  border-bottom: 1px solid #eaecef
}

.markdown-body h2 {
  font-size: 1.5em
}

.markdown-body h3 {
  font-size: 1.25em
}

.markdown-body h4 {
  font-size: 1em
}

.markdown-body h5 {
  font-size: .875em
}

.markdown-body h6 {
  font-size: .85em;
  color: #6a737d
}

.markdown-body ol,.markdown-body ul {
  padding-left: 2em
}

.markdown-body ol.no-list,.markdown-body ul.no-list {
  padding: 0;
  list-style-type: none
}

.markdown-body ol ol,.markdown-body ol ul,.markdown-body ul ol,.markdown-body ul ul {
  margin-top: 0;
  margin-bottom: 0
}

.markdown-body li {
  word-wrap: break-all;
  list-style-type: initial;
}

.markdown-body li>p {
  margin-top: 16px
}

.markdown-body li+li {
  margin-top: .25em
}

.markdown-body dl {
  padding: 0
}

.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600
}

.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px
}

.markdown-body table {
  display: block;
  width: 100%;
  /* width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; */
  max-width: 100%;
  overflow: auto
}

.markdown-body table th {
  font-weight: 600
}

.markdown-body table td,.markdown-body table th {
  padding: 6px 13px;
  border: 1px solid #dfe2e5
}

.markdown-body table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1
}

.markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa
}

.markdown-body table img {
  background-color: initial
}

.markdown-body img {
  max-width: 100%;
  box-sizing: initial;
  background-color: #fff
}

.markdown-body img[align=right] {
  padding-left: 20px
}

.markdown-body img[align=left] {
  padding-right: 20px
}

.markdown-body .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: initial
}

.markdown-body span.frame {
  display: block;
  overflow: hidden
}

.markdown-body span.frame>span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid #dfe2e5
}

.markdown-body span.frame span img {
  display: block;
  float: left
}

.markdown-body span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: #24292e
}

.markdown-body span.align-center {
  display: block;
  overflow: hidden;
  clear: both
}

.markdown-body span.align-center>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center
}

.markdown-body span.align-center span img {
  margin: 0 auto;
  text-align: center
}

.markdown-body span.align-right {
  display: block;
  overflow: hidden;
  clear: both
}

.markdown-body span.align-right>span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right
}

.markdown-body span.align-right span img {
  margin: 0;
  text-align: right
}

.markdown-body span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden
}

.markdown-body span.float-left span {
  margin: 13px 0 0
}

.markdown-body span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden
}

.markdown-body span.float-right>span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right
}

.markdown-body code,.markdown-body tt {
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27,31,35,.05);
  border-radius: 6px
}

.markdown-body code br,.markdown-body tt br {
  display: none
}

.markdown-body del code {
  text-decoration: inherit
}

.markdown-body pre {
  word-wrap: normal
}

.markdown-body pre>code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0
}

.markdown-body .highlight {
  margin-bottom: 16px
}

.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal
}

.markdown-body .highlight pre,.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px
}

.markdown-body pre code,.markdown-body pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: initial;
  border: 0
}

.markdown-body .csv-data td,.markdown-body .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap
}

.markdown-body .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: #fff;
  border: 0
}

.markdown-body .csv-data tr {
  border-top: 0
}

.markdown-body .csv-data th {
  font-weight: 600;
  background: #f6f8fa;
  border-top: 0
}